import React from 'react'
import { VideoObject } from 'schema-dts'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { MDXProvider, MDXProviderComponents } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
    Layout,
    SEO,
    Container,
    ResponsiveVideo,
    LocaleConfig,
} from 'modules/core'
import { ClientGrid } from '../clients'

const PillarResponsiveVideo = styled(ResponsiveVideo)`
    max-width: 840px;
    margin: 0 auto;
`

const MDX_COMPONENTS: MDXProviderComponents = {
    ResponsiveVideo: PillarResponsiveVideo,
    ClientGrid: ClientGrid,
}

interface PillarLayoutProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
        pillar: {
            title: string
            description: string
            illustration: any
            videoJSONLDs: VideoObject[]
            body: string
        }
    }
}

const PillarLayout = ({ path, pageContext }: PillarLayoutProps) => {
    const { localeConfig } = pageContext
    const { title, description, illustration, videoJSONLDs, body } =
        pageContext.pillar

    return (
        <Layout locale={localeConfig} path={path}>
            <SEO
                title={title}
                description={description}
                featuredImage={illustration}
            />
            {videoJSONLDs.length > 0 && (
                <Helmet>
                    {videoJSONLDs.map((videoJSONLD) => (
                        <script
                            key={videoJSONLD.contentUrl as string}
                            type="application/ld+json"
                        >
                            {JSON.stringify(videoJSONLD)}
                        </script>
                    ))}
                </Helmet>
            )}
            <PageHeader />
            <Container>
                <Illustration image={illustration.images.fallback.src} />
                <PostHeader>
                    <Title>{title}</Title>
                </PostHeader>
                <Pillar>
                    <MDXProvider components={MDX_COMPONENTS}>
                        <MDXRenderer>{body}</MDXRenderer>
                    </MDXProvider>
                </Pillar>
            </Container>
        </Layout>
    )
}

export default PillarLayout

const PageHeader = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.accent};
    height: 160px;
    z-index: -1;

    @media (max-width: 500px) {
        height: 72px;
    }
`

const PostHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`

const Title = styled.h1`
    font-size: 32px;
    line-height: 48px;
    margin: 8px 0 0 0;
    ${({ theme }) => theme.typography_presets.bold};
`

const Date = styled.div`
    font-size: 14px;
    line-height: 21px;
`

const Illustration = styled.div<{ image: string }>`
    height: 482px;
    width: 100%;
    margin: -80px 0 32px 0;
    background-color: ${({ theme }) => theme.colors.accent};
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: 252px;
        margin: -80px -12px 32px -12px;
        width: calc(100% + 24px);
    }
`

const Pillar = styled.div`
    font-family: 'gotham ', 'Hiragino Sans', sans-serif;
    color: #333;
    line-height: 1.7;
    margin: 0 auto;

    .strong {
        font-weight: 600;
        background-image: linear-gradient(
            transparent 60%,
            #e6d300 60%,
            #e6d300 100%
        );
    }

    .small {
        font-size: 80%;
    }

    a {
        color: #ff9535;
    }

    h3.withImg {
        padding-bottom: 4px;
        background: none;
        border-left: none;
    }

    .shadowing {
        border-bottom: 2px solid #469eb0;
    }

    .dictation {
        border-bottom: 2px solid #f17300;
    }

    .listening {
        border-bottom: 2px solid #43aa8b;
    }

    h3.withImg img {
        vertical-align: middle;
        margin-right: 16px;
    }

    .stepTitle1 {
        display: inline-block;
        padding: 4px 16px;
        background: #469eb0;
        border-radius: 8px;
        color: #fff;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    .stepTitle2 {
        display: inline-block;
        padding: 4px 16px;
        background: #f17300;
        border-radius: 8px;
        color: #fff;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    .stepTitle3 {
        display: inline-block;
        padding: 4px 16px;
        background: #43aa8b;
        border-radius: 8px;
        color: #fff;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    .ctabox {
        display: block;
        border: #dedede 3px solid;
        padding: 24px;
        text-decoration: none;
        color: #333333;
        border-radius: 8px;

        &:hover {
            box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
            border: 3px solid #e6d300;
        }

        span {
            font-weight: bold;
        }

        .box {
            display: flex;

            img {
                width: calc(33.3% - 10px);
                margin: 5px;
            }

            @media screen and (max-width: 600px) {
                display: flex;
                flex-direction: column;

                img {
                    width: 100%;
                }
            }
        }
    }

    h3 {
        border-left: 4px dotted #000;
        padding-left: 8px;
        margin-top: 40px;
        background: #eee;
    }

    .popout {
        text-align: center;
        padding: 24px 40px;
        background: #eeeeee;
        border-radius: 16px;
        margin: 32px 0;

        span {
            font-size: 140%;
            color: #d7565a;
            font-weight: 600;
        }
    }

    .strategy {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .strategyNo {
        background: #ffffff;
        border: 8px solid #e6d300;
        border-radius: 50%;
        color: #333333;
        display: inline-block;
        position: relative;
        height: 80px;
        width: 80px;
        font-size: 15px;
        font-weight: 600;
        flex-shrink: 0;

        span {
            position: absolute;
            display: inline-block;
            left: 0;
            top: 54%;

            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            line-height: 110%;
        }
    }

    .strategyTag {
        background: #e6d300;
        padding: 4px 16px;
        display: inline-block;
        margin-left: -16px;
        line-height: 20px;
        margin-top: 20px;
        flex-grow: 1;
    }

    .ctaButton {
        text-align: center;
        margin: 32px 0px;

        a {
            text-decoration: none;
            line-height: 130%;
            padding: 16px 24px;
            border-radius: 48px;
            background: rgb(0, 0, 0);
            display: inline-block;
            text-align: center;
            color: rgb(255, 255, 255);
        }
    }

    .listeningTable {
        border: 1px solid #dddddd;
        border-collapse: collapse;
        padding: 4px;

        th {
            width: 15%;
            min-width: 70px;
            padding: 5px 10px;
        }

        td {
            padding: 5px 10px;
        }
    }

    .row {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 20px;
    }

    .col {
        flex: 1 1 0;
        margin-bottom: 0;
        padding: 0 15px;

        img {
            vertical-align: top;
            aspect-ratio: 16 / 9;
        }
    }

    @media screen and (max-width: 600px) {
        .row {
            flex-direction: column;
        }

        .col {
            width: 100%;
        }
    }

    .titleUnderImg {
        display: block;
        padding: 4px;
        text-align: center;
        margin: 0;
        background: #e6d300;
        font-weight: bold;
    }

    .pointtag {
        display: inline-block;
        padding: 4px 16px;
        background: #525885;
        color: #fff;
        border-radius: 20px;
    }

    .studytag {
        display: inline-block;
        padding: 4px 16px;
        background: #43aa8b;
        color: #fff;
        border-radius: 20px;
    }

    .newCtaButton {
        display: table;
        border: 4px solid #e6d300;
        padding: 16px;
        font-size: 150%;
        border-left: 72px solid #e6d300;
        margin: 16px auto 40px;

        &:hover {
            box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
        }
    }

    .arrow {
        position: relative;
        display: inline-block;
        padding: 0 0 0 0;
        color: #000;
        vertical-align: middle;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;

        &::before,
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: '';
            vertical-align: middle;
        }
    }

    .sample1 {
        &::before {
            left: -64px;
            width: 22px;
            height: 4px;
            background: #333;
        }

        &::after {
            left: -60px;
            width: 16px;
            height: 16px;
            border-top: 4px solid #333;
            border-right: 4px solid #333;
            transform: rotate(45deg);
        }
    }

    .recommend a {
        text-decoration: none;
        color: #333333;
        &:hover {
            box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
        }
    }
`
